
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonCheckbox,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Signin",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonCheckbox,
  },
  data: () => {
    return {
      store: useStore(),
      email: "",
      password: "",
      twoFactorCode: "",
      showTwoFactor: false,
      rememberMe: false,
    };
  },
  methods: {
    updateRemember() {
      this.rememberMe = !this.rememberMe;
    },
    verifyTwoFactor() {
      // console.log(this.rememberMe);
      this.store
        .dispatch("verifyTwoFactor", {
          twoFactorCode: this.twoFactorCode,
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        })
        .then((res) => {
          if (res === "login") {
            this.$router.push({ path: "/" });
          }
          if (res.status === "Error") {
            alert(res.response.message);
          }
          if (res.errors) {
            Object.keys(res.errors).map((errorType) => {
              alert(res.errors[errorType][0]);
            });
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
    signIn() {
      if (this.store !== null) {
        this.store
          .dispatch("signIn", { email: this.email, password: this.password })
          .then((res) => {
            // Check 2FA
            if (res == "twoFactor") {
              console.log("Show 2FA Form");
              this.showTwoFactor = true;
            }

            if (res === "login") {
              this.$router.push({ path: "/" });
            }
            // Display errors
            if (res.status === "Error") {
              alert(res.message);
            }
            if (res.errors) {
              Object.keys(res.errors).map((errorType) => {
                alert(res.errors[errorType][0]);
              });
            }
          })
          .catch((error) => {
            alert(error.message);
          });
      } else {
        alert("no store found");
      }
    },
    loginFacebook() {
      if (this.store !== null) {
        this.store.dispatch("loginFacebook").then((res) => {
          if (res === "login") {
            this.$router.push({ path: "/" });
          } else if (res === "signUp") {
            this.$router.push({ path: "/register" });
          }
        });
      } else {
        alert("no store found");
      }
    },
    loginGoogle() {
      if (this.store !== null) {
        this.store.dispatch("loginGoogle").then((res) => {
          // console.log(res);
          if (res === "login") {
            this.$router.push({ path: "/" });
          } else if (res === "signUp") {
            this.$router.push({ path: "/register" });
          }
        });
      } else {
        alert("no store found");
      }
    },
  },
});
